<template>
    <div class="page bg-white" id="reorganize">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox">
                        <div class="f_w teamName m_b1">展览名称</div>
                        <el-select size="small" class="m_b2"
                                v-model="exhibitionValue"
                                filterable clearable
                                reserve-keyword
                                placeholder="请选择展览"
                                :remote-method="getExhibitionList"
                                :loading="exhibitionLoading" @clear="clearExhibition" @change="getExhibition" style="width: 100%;">
                            <el-option
                                    v-for="item in exhibitionOpt"
                                    :key="item.id"
                                    :label="item.exhibitionName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                        <div class="flex_l_c">
                            <el-row :gutter="20">
                                <el-col :span="18" class="f_w teamName">展览团队名称</el-col>
                                <el-col :span="6" class="text_right">
                                    <el-button size="mini" type="primary" icon="el-icon-plus" circle :disabled="exhibitionValue==''?true:false" @click="addTeam"></el-button>
                                </el-col>
                                <el-col :span="24" class="m_t1">
                                    <el-input maxlength="66" prefix-icon="el-icon-search" v-model="teamSrh" clearable placeholder="请输入关键字" size="small" :disabled="exhibitionValue==''?true:false"></el-input>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="exhibitionTip" v-show="exhibitionValue == ''">请选择展览</div>
                        <div v-show="exhibitionValue != ''">
                            <el-table v-loading="loading2"
                                      :data="catalogueList"
                                      ref="catalogueTable"
                                      size="small"
                                      height="calc(100vh - 392px)"
                                      class="table" highlight-current-row
                                      @current-change="handleCurrentChange">
                                <el-table-column prop="serialNumber" label="序号" width="50" show-overflow-tooltip/>
                                <el-table-column prop="name" label="名称" show-overflow-tooltip/>
                                <el-table-column fixed="right" label="操作" width="140">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="primary" plain icon="el-icon-edit" @click="edit(scope.row)"></el-button>
                                        <el-button size="mini" type="primary" plain icon="el-icon-copy-document" @click="copy(scope.row)"></el-button>
                                        <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="delTeam(scope.row.id)"></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageNo"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="total"
                                    background
                                    layout="prev, pager, next">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump">
                    <el-row class="headBox">
                        <el-col :span="10">
                            <div class="flex_l_c">
                                <el-input maxlength="66" class="flex1 m_r1" v-model="fileSrh" placeholder="请输入关键字" size="small" clearable></el-input>
                                <el-button icon="el-icon-search" type="primary" size="small" @click="searchList">查询</el-button>
                                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                            </div>
                        </el-col>
                        <el-col :span="14" class="text_right">
                            <el-button icon="el-icon-plus" size="small" type="primary" :disabled="teamId==''&&exhibitionValue==''" @click="view('add',teamId)">新建</el-button>
                            <el-button type="danger" size="small" icon="el-icon-delete" @click="del()" :disabled="dataListSelections.length <= 0" plain>删除</el-button>
                        </el-col>
                    </el-row>
                    <div class="catalogueBox">
                        <el-table
                                :data="fileList"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                v-loading="loading"
                                height="calc(100vh - 290px)"
                                class="table">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column prop="serialNumber" label="序号" width="110" sortable></el-table-column>
                            <el-table-column prop="exhibitionTeamName" label="展览组织" width="120" sortable show-overflow-tooltip/>
                            <el-table-column prop="name" label="姓名(名称）" width="120" sortable show-overflow-tooltip/>
                            <el-table-column prop="gender" label="性别" sortable show-overflow-tooltip/>
                            <el-table-column prop="dateOfBirth" label="出生日期" width="120" :formatter="formatterBirth" sortable show-overflow-tooltip/>
                            <el-table-column prop="position" label="职务" sortable show-overflow-tooltip/>
                            <el-table-column prop="theTitle" label="职称" sortable show-overflow-tooltip/>
                            <el-table-column prop="professional" label="专业" sortable show-overflow-tooltip/>
                            <el-table-column prop="specialty" label="专长" sortable show-overflow-tooltip/>
                            <el-table-column prop="national" label="民族" sortable show-overflow-tooltip/>
                            <el-table-column prop="educationBackground" label="学历" sortable show-overflow-tooltip/>
                            <el-table-column prop="phone" label="联系电话" width="120" sortable show-overflow-tooltip/>
                            <el-table-column prop="contribution" label="承担的工作（贡献）" width="180" sortable show-overflow-tooltip/>
                            <el-table-column fixed="right" label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑</el-button>
                                    <el-button size="mini" type="primary" plain @click="del(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle2"
                                @current-change="currentChangeHandle2"
                                :current-page="pageNo2"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize2"
                                :total="total2"
                                background
                                layout="total, prev, pager, next, sizes">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--新建、编辑新建成员-->
        <AddMember ref="addMember" @refreshDataList="getMember"></AddMember>
        <!--新建、编辑团队-->
        <AddTeam ref="addTeam" @refreshDataList="getTeam"></AddTeam>
    </div>
</template>

<script>
    import AddMember from './addMember' // 新建成员
    import AddTeam from './addTeam' // 新建团队

    export default {
        components: {AddTeam, AddMember},
        data() {
            return {
                visibleLog: false,
                teamSrh: '',
                catalogueList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading2: false,
                fileSrh: '',
                fileList: [],
                dataListSelections: [],
                loading: false,
                pageNo2: 1,
                pageSize2: 10,
                total2: 0,
                teamId: "",
                genderOpt: [
                    {
                        label: '男',
                        value: '0'
                    },
                    {
                        label: '女',
                        value: '1'
                    }
                ],
                exhibitionValue: '',
                exhibitionLoading: false,
                exhibitionOpt: []
            }
        },
        watch: {
            // 实时输入查询团队
            teamSrh() {
                this.search();
            },
        },
        mounted() {
            this.getExhibitionList();
            // this.getMember();
        },
        methods: {
            // 获取展览数据
            getExhibitionList(query) {
                this.exhibitionLoading = true
                this.$axios(this.api.zlzs.exhibitionInall, {}, 'get').then(data => {
                    if (data && data.status) {
                        this.exhibitionOpt = data.data
                        this.exhibitionLoading = false
                    }
                })
            },
            // 清除展览选择
            clearExhibition() {
                this.teamId = ""
                this.getExhibitionList('')
            },
            // 展览选择
            getExhibition(){
                this.teamId ='-1';
                this.pageNo = 1
                this.getTeam();
                this.searchList()
            },
            formatterBirth(row) {
                if(row.dateOfBirth) {
                    let date = row.dateOfBirth.split('-')
                    return date[0] + '年' + date[1] + '月'
                }
            },
            // 新建、编辑
            view(method, row) {
                if(method =="add" && this.teamId == "" || this.teamId == "-1"){
                    this.$message.error("请选择团队")
                    return false
                }
                this.$refs.addMember.init(method, row)
            },
            // 表格多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            search() {
                this.teamId = ""
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.getTeam();
                this.fileList = [];
            },
            // 查询团队
            getTeam() {
                this.loading2 = true
                this.$axios(this.api.zlzs.exhibitionTeamList, {
                    'name': this.teamSrh,
                    'informationId': this.exhibitionValue,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading2 = false
                    }
                })
            },
            // 重置
            resetSearch() {
                this.fileSrh = '';
                this.teamId = "-1"
                this.exhibitionValue = ""
                this.getMember()
            },
            // 点击团队
            handleCurrentChange(val) {
                this.$refs.catalogueTable.setCurrentRow(val);
                this.teamId = val.id
                this.searchList()
            },
            searchList() {
                this.pageNo2 = 1;
                if(this.teamId ==""){
                    this.teamId = "-1"
                }
                this.getMember();
            },
            // 查询成员
            getMember() {
                this.loading = true
                this.$axios(this.api.zlzs.TeamMemberList, {
                    'exhibitionTeamId': this.teamId,
                    "name": this.fileSrh,
                    'current': this.pageNo2,
                    'size': this.pageSize2,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.fileList = data.data.records
                        this.fileList.forEach(f=>{
                            if(f.gender=="0"){
                                f.gender ="男";
                            }else if(f.gender=="1"){
                                f.gender ="女"
                            }
                        })
                        this.total2 = parseInt(data.data.total)
                        this.loading = false
                    }
                })

            },
            // 新建团队
            addTeam() {
                this.$refs.addTeam.init("add",this.exhibitionValue)
            },
            //复制团队数据
            copy(row){
                this.$copyText(row.name).then(() => {
                    // this.$axios(this.api.zlzs.exhibitionTeamSave, this.inputForm, type).then(data => {
                    //     if (data && data.status) {
                    //         this.$message.success('复制成功')
                    //         this.getTeam()
                    //     }
                    // })
                    this.$refs.addTeam.init("copy", row)
                }, () => {
                    this.$message.error('复制失败')
                })
            },
            edit(row){
                row.informationId =this.exhibitionValue
                this.$refs.addTeam.init("edit",row)
            },
            // 删除团队
            delTeam(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.exhibitionTeamUpdateById, {
                        'id': id,
                        'delFlag':1,
                    }, 'put').then(data => {
                        if (data && data.status) {
                            this.$message.success("删除成功")
                            this.teamId ='-1';
                            this.getTeam()
                            this.searchList()
                        }else{
                            this.$message.error("删除失败")
                        }
                    })
                })
            },
            //删除成员数据
            del(id) {
                // 批量删除多个id用逗号隔开
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                console.log(this.dataListSelections)
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.TeamMemberRemove, {
                        'ids': ids,
                    }, 'delete').then(data => {
                        if(data && data.status){
                            this.$message.success(data.msg)
                            if(ids.split(",").length==this.fileList.length){
                                this.pageNo2=1
                            }
                            this.getMember()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 团队每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getTeam();
            },
            // 团队当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getTeam();
            },
            // 成员每页数
            sizeChangeHandle2(val) {
                this.pageSize2 = val;
                this.pageNo2 = 1;
                this.getMember();
            },
            // 成员当前页
            currentChangeHandle2(val) {
                this.pageNo2 = val;
                this.getMember();
            },
        }
    }
</script>

<style scoped>
.teamName {
    font-size: 1.3rem;
}
    .exhibitionTip {
        height: calc(100vh - 335px);
        line-height: calc(100vh - 335px);
        text-align: center;
        color: #909399;
    }
</style>
